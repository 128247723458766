import React, { useContext, useState } from 'react'
import { Button, Card, Row, Col, Stack } from 'react-bootstrap'
import { Contexti } from '../../context/Context'
import { Heart } from '@phosphor-icons/react/dist/ssr'
import { useNavigate } from 'react-router-dom'

function CartItem(props) {
    const { id, productName, price, productImage} = props.data
    const {cartItems, addToCart, removeFromCart, manualCartCountEntry} = useContext(Contexti)

    const [isClicked, setIsClicked] = useState(false)
    const [isClicked2, setIsClicked2] = useState(false)

    const navigate = useNavigate()
    const routeHandler = (stte) => {
      
      navigate(`/product/${stte}`)
    }



    const clickHandlerR = () => {
        setIsClicked((prevState) => !prevState)
        removeFromCart(id)
        setTimeout(() => {
          setIsClicked((prevState) => !prevState)
        },200)
    }
    const clickHandlerA = () => {
      setIsClicked2((prevState) => !prevState)
      addToCart(id)
      setTimeout(() => {
        setIsClicked2((prevState) => !prevState)
      },200)
  }
  return (

      <div className='productContainer'>
          <Stack direction='horizontal' gap={4} className='productContainerStack'>
            <img src={productImage} className='productImg' onClick={() => routeHandler(id) } />
            <div className='verticalStack'>
              <h2 className='productTitle' onClick={() => routeHandler(id) }>{productName}</h2>
              <span className='productControls'>
                Rs.{price}/-<br/>
                <Stack direction='horizontal' gap={2}>
                <button onClick={clickHandlerR} className={isClicked ? "Buttni Buttni2" : "Buttni"}>-</button>
                <span>{cartItems[id]}</span>
                <button onClick={clickHandlerA} className={isClicked2 ? "Buttni Buttni2" : "Buttni"}>+</button>
                </Stack>
                

              </span>

            </div>
          </Stack>
          <Heart size={32} className='HeartIcon'/>
      </div>





    // <Row className="containerChildRow text-center">
    //   <Col className="text-center">
    //   <Card  className='text-center cartsCard'>
    //     <Card.Img src={productImage} className='cardImgCart'/>
    //     <Card.Body>
    //       <Card.Title>{productName}</Card.Title>
    //       <Card.Text>${price}</Card.Text>
    //     </Card.Body>
    //     <Card.Footer>
    //       <Button variant='success' onClick={() => removeFromCart(id)} > - </Button>
    //       <span>{cartItems[id]}</span>
    //       <Button variant='success' onClick={() => addToCart(id)} > + </Button>
    //     </Card.Footer>
    //   </Card>
    //   </Col>
    // </Row>
  )
}

export default CartItem

import React from 'react'
import { useState } from 'react'
import { Carousel, Container, Row, Col, Card } from 'react-bootstrap'
import { BrowserRouter, Link, Route, Routes, useNavigate } from 'react-router-dom'
import ProductList from './ProductList'
import Footer_pr from './Footer_pr'

function Home() {
  const [index, setIndex] = useState(0)

    const handleSelect = (selectedIndex) => {
            setIndex(selectedIndex)
    }

    const navigate = useNavigate()
    const caraRedirect = (proId) => {
      navigate(`/${proId}`)
    }

  return (
    <>
    <Container fluid className='carouselContainer' >

      <Row>
        <Col className='zeropadcol'>
        <Carousel className='cara' activeIndex={index} onSelect={handleSelect} data-bs-theme="dark" touch controls={false}>
        <Carousel.Item>
          <img src='images/Honor90PRO_Banner.jpg' className='caraImg' onClick={() => caraRedirect(2)} />
        </Carousel.Item>

        <Carousel.Item>
          <img src='images/honorX9B.jpg' className='caraImg' onClick={() => caraRedirect(3)} />
        </Carousel.Item>

        <Carousel.Item>
          <img src='images/OnePlusBanner2.jpg' className='caraImg' onClick={() => caraRedirect(0)} />
        </Carousel.Item>
      </Carousel>  
        </Col>


      </Row>

      <Row>
      <Col className='zeropadcol'>
      
<ProductList />

      </Col>
      </Row>

      <Row className='mt-5 footerParentColRow'>
        <Col className='zeropadcol footerParentCol text-center'>
        <Footer_pr/>
        </Col>
      </Row>
    </Container>
   
     
    
     
    </>
  )
}

export default Home

import React from 'react'
import { Container, Row, Col,  Stack } from 'react-bootstrap'
// import RotatingIcon from './RotatingIcon'
import logo from "../../Assets/images/LoGoBix4.png"
import { GithubLogo, PersonSimpleThrow, TelegramLogo } from '@phosphor-icons/react'


function Footer_pr() {
    const buttonLink = (link) => {
        window.location.href = link
    }

  return (
    <>
     <Container  className=''>
        <Row className='anLogoRow text-center'>
        <img src={logo} className='anLogo'/>
        </Row>
        <Row className='footerPara'>
        <Container>A small, reliable and complete platform that serves your need 24*7 for any product, BixWieeee Now!</Container>
        </Row>
        <Row className='pt-5'>
            <Col>
            <a href='#' className='anchorFooter'>Address</a>
            </Col>
            <Col>
            <a href='#tokeni'  className='anchorFooter'>Tokenomics</a>
            </Col>
            <Col>
            <a href='#roadmap'  className='anchorFooter'>Roadmap</a>
            </Col>
            <Col>
            <a href='#'  className='anchorFooter'>Platform</a>
            </Col>
            <Col>
            <a href='#'  className='anchorFooter'>Whitepaper</a>
            </Col>
        </Row>
       
        <Row className='mt-5 pt-4'>
            <h6>Developed by <a href='https://linkedin.com/in/jishan-arzoo-37788a25b' className='coder'>Jishan Arzoo</a></h6>
        </Row>
        <Row className='text-center pt-2'>
            <Stack direction='horizontal' gap={4} style={{display: "flex", justifyContent: "center", alignItems: "center"}} >
                
                <GithubLogo size={32} className='footerLogos' onClick={() => buttonLink("https://www.google.com")} />
                <TelegramLogo  size={32} className='footerLogos' onClick={() => buttonLink("https://www.google.com")}/>
                <PersonSimpleThrow size={32} className='footerLogos'  onClick={() => buttonLink("https://www.youtube.com")} />

            </Stack>
        </Row>
    </Container> 
    </>
  )
}

export default Footer_pr





import { createContext, useState } from "react";
import { PRODUCTS } from "../products"
import { isEditable } from "@testing-library/user-event/dist/utils";

export const Contexti = createContext(null)

function getCart() {
    let cart = {}
    for ( let i = 0 ; i < PRODUCTS.length + 1 ; i++){
        cart[i] = 0;
    }    
  return cart;
}



export const ShopContextProvider = (props) => {

    
const [cartItems, setCartItems] = useState(getCart())
const [totalCartCount, setTotalCartCount] = useState(0)


const getTotalCartCount = () => {
    
    for (let i = 0; i < PRODUCTS.length +1; i++){
        setTotalCartCount((prevState) => prevState + cartItems.i  )
        
    }
}

const getTotalCartAmount = () => {
    let totalAmount = 0;
    for (const item in cartItems) {
      if (cartItems[item] > 0) {
        let itemInfo = PRODUCTS.find((product) => product.id === Number(item));
        totalAmount += cartItems[item] * itemInfo.price;
      }
    }
    return totalAmount;
  }


const addToCart = (itemId) => {
setCartItems((prevState) => ({ ...prevState, [itemId]: prevState[itemId] + 1}) )
setTotalCartCount((prevState) => prevState + 1 )
}
const removeFromCart = (itemId) => {
    setCartItems((prevState) => ({ ...prevState, [itemId]: prevState[itemId] - 1}) )
    setTotalCartCount((prevState) => prevState - 1 )
}
const manualCartCountEntry = (newQuantity, itemId) => {
    setCartItems((prevState) => ({...prevState, [itemId]: newQuantity }))
}

const contextValue = {
    totalCartCount,
    cartItems,
    addToCart,
    removeFromCart,
    manualCartCountEntry,
    getTotalCartAmount
}

return (
    <Contexti.Provider value={contextValue}>
    {props.children}
    </Contexti.Provider>
)

}




import { Heart } from '@phosphor-icons/react'
import { ShoppingBag } from '@phosphor-icons/react/dist/ssr'
import React from 'react'
import { useContext } from 'react'
import { Col, Container, Card, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { Contexti } from '../../context/Context'

function Product(props) {
    const { id ,productName, price, productImage} = props.data

    const navigate = useNavigate()
    const ProdiPage = (stati) => {
        navigate(`/product/${stati}`)
    }

    const {addToCart, cartItems} = useContext(Contexti)
    const productCount = cartItems[id]
  return (
    
      <Col className='zeropadcol pt-4' xs={6} sm={{span: "3", offset: "0"}}>
           <Container>
           <Card className='productCard' >
                <Card.Img variant='top' src={productImage} className='cardImg'  />
                <Card.Body className='cardBody'>
                  <Card.Title className='productTitli' onClick={() => ProdiPage(id)}>{productName}</Card.Title>
                  <Card.Text>Rs. {price}</Card.Text>
                  
                  
                </Card.Body>
                <Card.Footer style={{background: "pink"}}>
                  <div className='footerCard'>
                  <Button variant='light' onClick={() => addToCart(id)} className='cardButton'>
                    Add to Cart {productCount > 0 && <> ({productCount}) </>}
                    </Button>
                  {/* <Heart className='ml-4' size={32} weight='fill' fill='red' /> */}
                  </div>
                  
                  </Card.Footer>

            </Card>
           </Container>
            </Col>
    
  )
}

export default Product

import React from 'react'
import { useState } from 'react'
import { PRODUCTS } from '../products'
import { Modal, Button } from 'react-bootstrap'
import { useParams } from 'react-router-dom'

const ProductDetails = () => {
  let { id }= useParams()
 const [idProduct,setIdProduct] = useState(id)
 
 
//  useEffect(()=>{
//   setIdProduct((prevState) => prevState - 1)
//  },[])

 const {productName, price, productImage} = PRODUCTS[idProduct]

//  console.log(PRODUCTS[idProduct]);
  
 
  return (
    <>
    {/* <h1>Your order id is { idProduct }</h1>
    <div>
    <img src={productImage} />
    </div> */}
    <Modal.Dialog variant="success">
      <Modal.Header>
<Modal.Title>{productName}</Modal.Title>
      </Modal.Header>
      <Modal.Body><img src ={productImage}/></Modal.Body>
      <Modal.Footer>
        <Button variant='success'>{price}</Button>
      </Modal.Footer>
    </Modal.Dialog>
    
    </>
    
  )
}

export default ProductDetails

import './App.css';
import "./responsive.css"
import { BrowserRouter as Router, Routes, Route, Link, useParams, useHistory, useNavigate } from 'react-router-dom';
import NavBar_pr from './RU_components/NavBar_pr';
import { Button, Col, Container, Modal, ModalFooter, Row } from 'react-bootstrap';
import Home from './pages/Home/Home';
import Cart from './pages/Cart/Cart';
import { PRODUCTS } from './products';
import { useEffect, useState } from 'react';
import ProductDetails from './RU_components/ProductDetails';

// const OrderDetails = () => {
//   let { id }= useParams()
//  const [idProduct,setIdProduct] = useState(id)
 
 
// //  useEffect(()=>{
// //   setIdProduct((prevState) => prevState - 1)
// //  },[])

//  const {productName, price, productImage} = PRODUCTS[idProduct]

//  console.log(PRODUCTS[idProduct]);
  
 
//   return (
//     <>
//     {/* <h1>Your order id is { idProduct }</h1>
//     <div>
//     <img src={productImage} />
//     </div> */}
//     <Modal.Dialog variant="success">
//       <Modal.Header>
// <Modal.Title>{productName}</Modal.Title>
//       </Modal.Header>
//       <Modal.Body><img src ={productImage}/></Modal.Body>
//       <Modal.Footer>
//         <Button variant='success'>{price}</Button>
//       </Modal.Footer>
//     </Modal.Dialog>
    
//     </>
    
//   )
// }

function App() {
  

  // const navigate = useNavigate()
  // const routeHandler =(stte) => {
  //   navigate("/")
  // }
  
  return (
    // <Router>

      <Container fluid>

<Row className='mb-5 pb-2 pb-sm-3'>
  <Col className='zeropadcol'>
<NavBar_pr />
  </Col>
</Row>

<Row>
  <Col className='zeropadcol'>
<Routes>
      <Route path='/' element={<Home />} />
      <Route path='/cart' element={<Cart />} />
    
      
      <Route path="/product/:id" Component={ProductDetails} />
    </Routes>
  </Col>
</Row>

      </Container>

      // </Router>
    
  );
}


export default App;


{/* <Icons>
<ShoppingCart size={32} />
<ShoppingCartSimple size={32} />
<ShoppingBag size={32} />
<ShoppingBagOpen size={32} />
<Wallet size={32} />
<Heart size={32} />
<MapPin size={32} />
</Icons> */}
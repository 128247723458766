import product1 from "./Assets/images/OnePlus.png"
import product2 from "./Assets/images/OneplusNord.png"
import product3 from "./Assets/images/Honor90Pro.png"
import product4 from "./Assets/images/HonorX9B.png"
import product5 from "./Assets/images/victus.png"
import product6 from "./Assets/images/HonorChoiceWatch.png"
import product7 from "./Assets/images/Chair2.png"
import product8 from "./Assets/images/Chair.png"
import product9 from "./Assets/images/BritanniaRollYo.png"
import product10 from "./Assets/images/MaggieVEGATTA.png"
import product11 from "./Assets/images/JimJam.png"
import product12 from "./Assets/images/HersheyKisses.png"

export const PRODUCTS = [
    {
        id: 0,
        productName: "OnePlus 20 Pro",
        price: 40000,
        productImage: product1
    },
    {
        id: 1,
        productName: "OnePlus Nord",
        price: 40000,
        productImage: product2
    },
    {
        id: 2,
        productName: "Honor 90 Pro",
        price: 27000,
        productImage: product3
    },
    {
        id: 3,
        productName: "Honor X9B 5G",
        price: 21999,
        productImage: product4
    },
    {
        id: 4,
        productName: "HP Victus",
        price: 219999,
        productImage: product5
    },
    {
        id: 5,
        productName: "Honor Choice Watch",
        price: 5999,
        productImage: product6
    },
    {
        id: 6,
        productName: "WFH Chair",
        price: 12999,
        productImage: product7
    },
    {
        id: 7,
        productName: "Gaming Chair",
        price: 6999,
        productImage: product8
    },
    {
        id: 8,
        productName: "Britannia Rolls",
        price: 19,
        productImage: product9
    },
    {
        id: 9,
        productName: "Veg Atta Maggi",
        price: 19,
        productImage: product10
    },
    {
        id: 10,
        productName: "JimJam Biscuits",
        price: 20,
        productImage: product11
    },
    {
        id: 11,
        productName: "Hershey KiSSes",
        price: 119,
        productImage: product12
    },
]
import React, { useContext } from 'react'
import { Contexti } from '../../context/Context'
import { PRODUCTS } from '../../products'
import CartItem from './CartItem'
import { Container, Row, Col } from 'react-bootstrap'
import "./Cart.css"
import { useNavigate } from 'react-router-dom'

function Cart() {
  const {cartItems, removeFromCart, getTotalCartAmount} = useContext(Contexti)
  const totalAmount = getTotalCartAmount()
  const navigate = useNavigate();
  return (
    <>

    <Container fluid>
      <Row >
      <Col className="zeropadcol">
        <h1 className='text-center'>Your Cart:</h1>
        </Col>
      </Row>

      <Row>
      <Col className='cartCardContainer'>
        <Container fluid>
          {PRODUCTS.map((product) => {
            if (cartItems[product.id] !== 0){
            return <CartItem data={product} key={product.id} />
          }
            })}
        </Container>
  </Col>
</Row>

<Row>
  <Col className='zeropadcol'>
    <div className='checkout'>
              <p className='subtotal'>SubTotal: Rs. {totalAmount}</p>
              <div className='checkoutOptions'>
              <button onClick={() => navigate("/") } className='checkoutiBtn'>Continue Shopping</button>
              <button className='checkoutiBtn'>Checkout</button>
              </div>
              
    </div>
  </Col>
</Row>
      
    </Container>


    
      
      
    </>
  )
}

export default Cart

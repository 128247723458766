import React, { useContext } from 'react'
import { Badge, Container, Nav, Navbar } from 'react-bootstrap'
import { Link, NavLink } from 'react-router-dom'
import { ShoppingCartSimple } from '@phosphor-icons/react'
import { Contexti } from '../context/Context'

function CartIconi(props) {
  const { totalCartCount} = useContext(Contexti)
  return (
    <Nav.Link href='#' className={props.classyName} ><Link to="/cart"  className='navilinko'><ShoppingCartSimple size={32} /><Badge bg='info' className='cartBadge' style={{borderRadius: "100%"}}>{totalCartCount}</Badge></Link></Nav.Link>
  )
}

function NavBar_pr() {
  return (
    <>
      <Navbar className='navbar'  expand="sm"  collapseOnSelect={true}>
        <Container>
        <Navbar.Brand className='navbrand'>BixWie</Navbar.Brand>
        {/* <Nav.Link href='#' className='navilinko ms-auto cartIconPhone' ><Link to="/cart"  className='navilinko'><ShoppingCartSimple size={32} /><Badge bg='secondary' className='cartBadge'>4</Badge></Link></Nav.Link> */}
        <CartIconi classyName="navilinkoMain ms-auto cartIconPhone"/>
        <Navbar.Toggle style={{background: 'white'}} />


        <Navbar.Collapse>
          <Nav className='ms-auto' >
          <Nav.Link href='#' className='mr-4 navilinkoMain '><Link to="/" className='navilinko'>Home</Link></Nav.Link>
          
          {/* <Nav.Link href='#' className='navilinko ms-auto cartIconBig' ><Link to="/cart"  className='navilinko'><ShoppingCartSimple size={32} /><Badge bg='secondary' className='cartBadge'>4</Badge></Link></Nav.Link> */}
          <CartIconi classyName="navilinkoMain cartIconBig" />
            
          </Nav>
            
        </Navbar.Collapse>
        </Container>
        
      </Navbar>
    </>
  )
}

export default NavBar_pr
